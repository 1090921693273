html {
    overflow-x: hidden
}

body {
    background-color: #F9FAFB;
    color: #101828;
    font-size: 16px;
    line-height: 125%;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
}

.ck-powered-by{
    display: none;
}