

@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
        url("styles/fonts/Manrope-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
        url("styles/fonts/Manrope-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
        url("styles/fonts/Manrope-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
        url("styles/fonts/Manrope-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
        url("styles/fonts/Manrope-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
        url("styles/fonts/Manrope-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
        url("styles/fonts/Manrope-Light.ttf") format("truetype");
    font-weight: 300;
}